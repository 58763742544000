import type { FC } from "react";
import React, { useEffect } from "react";
import FilesCubit from "src/state/FilesCubit/FilesCubit";
import { useBloc } from "src/state/state";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/css";
import { useIntersectionObserver } from "@react-hooks-library/core";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";

const ShowImageKF = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.1);
    filter: blur(0.2rem);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
`;

const PreviewWrap = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 0.4rem;
  border: 2px solid var(--greys-light-gray, #e6e3db);
  background-color: var(--secondary-dark-cream, #f2efe7);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    animation: ${ShowImageKF} 0.4s ease-in-out;
    position: relative;
    z-index: 2;
    background-color: var(--secondary-dark-cream, #f2efe7);
  }

  .async-content {
    position: absolute;
    inset: 0;
    z-index: 1;
    display: flex;
    height: auto;
    width: auto;
  }
`;

const DocumentPreview: FC<{
  id: string;
  width?: string;
  ratio?: number;
}> = ({ id, width, ratio }) => {
  const [previewUrl, setPreviewUrl] = React.useState<string | undefined>();
  const [, { loadPreview }] = useBloc(FilesCubit);

  const previewRef = React.useRef<HTMLDivElement>(null);
  const { inView } = useIntersectionObserver(previewRef);

  const initLoad = () => {
    void loadPreview(id, 300, 300).then(setPreviewUrl);
  };

  useEffect(() => {
    if (inView && previewUrl === undefined) {
      initLoad();
    }
  }, [inView]);

  // handle id change
  useEffect(() => {
    setPreviewUrl(undefined);
    if (inView) {
      initLoad();
    }
  }, [id]);

  return (
    <>
      <PreviewWrap
        ref={previewRef}
        style={{
          width: width ?? "min(130px, 25%)",
          aspectRatio: ratio ?? "1.3"
        }}
      >
        {previewUrl !== "" && <AsyncContent check={[false]} />}
        {previewUrl && <img src={previewUrl} aria-hidden alt="" />}
      </PreviewWrap>
    </>
  );
};

export default DocumentPreview;
